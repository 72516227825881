import { isInt } from "@fullcalendar/common";
import axios from "axios";

function getRandomMinMax(min = 1, max = 31) {
  return Math.random() * (max - min) + min;
}

export function uniqueArray(arr) {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}

export function mapOptions(targets, options, key){
  let optionsResult = []
  targets.forEach(target => {
      optionsResult.push(...target[key].value)
      // optionsResult.push(...target[key].map(d => ({label: d.label || d.value || d, value: d.value || d.label || d})))
  })
  return uniqueArray([...optionsResult, ...options])
}

export function transformUserMembership(membership) {
  var result = {...membership};
  result.contact_methods.forEach((method) => {
    result[method.toLowerCase()] = true;
  })
  result.contact_methods_str = result.contact_methods.join(", ")
  result.drinks_str = result.drinks.join(", ")
  return result;
}

export async function getGenderOptions(){
  return new Promise((resolve) => {
    resolve(["Male", "Female", "Gay"])
  })
  const { data } = await axios.get('/getGenderOptions')
  return data
}

export async function getDrinkOptions(){
  return new Promise((resolve) => {
    resolve(["Vodka", "Gin", "Whisky"])
  })
  const { data } = await axios.get('/getDrinkOptions')
  return data
}

export async function getBrandOptions(){
  return new Promise((resolve) => {
    resolve(["Absolute", "Gordon's", "Jack Daniels"])
  })
  const { data } = await axios.get('/getBrandOptions')
  return data
}

export async function getEvents(){
  return new Promise((resolve) => {
    // const events = []
    // new Array(200).fill(0).forEach((_, i) => {
    //   const rand = String(~~getRandomMinMax()).padStart(2, "0");
    //   events.push({
    //     title: "event " + i,
    //     date: `2022-10-${rand}`,
    //     id: "e" + i,
    //     allDay: true,
    //   });
    // });
    const events = [
      {title: "Bloody Valentines", date: "2022-10-01", target: 120, reserved: 120, waiting: 20, id: 1},
      {title: "Gin Night", date: "2022-10-02", target: 100, reserved: 100, waiting: 0, id: 2},
      {title: "Wine Night", date: "2022-10-03", target: 100, reserved: 100, waiting: 0, id: 3},
      {title: "Rocker Party", date: "2022-10-29", target: 80, reserved: 50, waiting: undefined, id: 4},
      {title: "EDM Party", start: "2022-10-29T00:00:00", end:"2022-10-29T12:00:00", allDay: false, target: 100, reserved: 100, waiting: 0, id: 5},
    ]
    resolve(events)
  })
  const { data } = await axios.get('/getAllEvent')
  return data
}

export async function getUsersTargetCriteria(tier){
  return new Promise((resolve) => {
    const users = [
      { rank: "platitnum", nickname: "Woosen", prefix_name: "Ms.", first_name: "Viritipa", last_name: "Pakdeeprasong", gender: "Female", age: 42, drinks: ["Gin", "Vodka"], contact_methods: ["SMS", "Email"], status: "Not invited", reserved: 0, id: 1},
      { rank: "gold", nickname: "kao", prefix_name: "Ms.", first_name: "Supassra", last_name: "Thanachat", gender: "Female", age: 29, drinks: ["Whisky", "Gin"], contact_methods: ["SMS"], status: "Waiting List", reserved: 0, id: 2},
      { rank: "silver", nickname: "Jame", prefix_name: "Mr.", first_name: "Jirayu", last_name: "Tangsrisuk", gender: "Gay", age: 35, drinks: ["Vodka"], contact_methods: ["Email"], status: "Invited", reserved: 0, id: 3},
      { rank: "platitnum", nickname: "Woosen", prefix_name: "Ms.", first_name: "Viritipa", last_name: "Pakdeeprasong", gender: "Female", age: 42, drinks: ["Gin", "Vodka"], contact_methods: ["SMS", "Email"], status: "Not invited", reserved: 0, id: 4},
      { rank: "gold", nickname: "kao", prefix_name: "Ms.", first_name: "Supassra", last_name: "Thanachat", gender: "Female", age: 29, drinks: ["Whisky", "Gin"], contact_methods: ["SMS"], status: "Waiting List", reserved: 0, id: 5},
      { rank: "silver", nickname: "Jame", prefix_name: "Mr.", first_name: "Jirayu", last_name: "Tangsrisuk", gender: "Gay", age: 35, drinks: ["Vodka"], contact_methods: ["Email"], status: "Reserved", reserved: 2, reserved_method: null, id: 6},
      { rank: "platitnum", nickname: "Woosen", prefix_name: "Ms.", first_name: "Viritipa", last_name: "Pakdeeprasong", gender: "Female", age: 42, drinks: ["Gin", "Vodka"], contact_methods: ["SMS", "Email"], status: "Reserved", reserved: 2, reserved_method: "SMS", id: 7},
    ]
    resolve(users.map(transformUserMembership))
    })
    const { data } = axios.get('/getUsersMembership')
    return data
}

export async function postGenderOptions(options){
  return new Promise((resolve) => {
    resolve()
  })
  const { data } = await axios.post('/postGenderOptions', options)
  return data
}

export async function postDrinkOptions(options){
  return new Promise((resolve) => {
    resolve()
  })
  const { data } = await axios.post('/postDrinkOptions', options)
  return data
}

export async function postBrandOptions(options){
  return new Promise((resolve) => {
    resolve()
  })
  const { data } = await axios.post('/postBrandOptions', options)
  return data
}

export function onSelectedOption(cls, options, tier, refKey){
  var unique;
      switch (refKey) {
        case "gender":
          unique = uniqueArray([...cls.genderOptions, ...options]);
          if (~unique.findIndex((e) => e.toLowerCase() === "all")) {
            const index = cls.targets.findIndex((e) => e.tier === tier);
            cls.targets[index].genders = ["All"]
          }
          cls.genderOptions = unique;
          break;
        case "drink":
          unique = uniqueArray([...cls.drinkOptions, ...options]);
          if (~unique.findIndex((e) => e.toLowerCase() === "all")) {
            const index = cls.targets.findIndex((e) => e.tier === tier);
            cls.targets[index].drinks = ["All"]
          }
          cls.drinkOptions = unique;
          break;
        case "brand":
          unique = uniqueArray([...cls.brandOptions, ...options]);
          if (~unique.findIndex((e) => e.toLowerCase() === "all")) {
            const index = cls.targets.findIndex((e) => e.tier === tier);
            cls.targets[index].brands = ["All"]
          }
          cls.brandOptions = unique;
          break;
        default:
          break;
      }
}

export function onSelectedFormOption(cls, options, refKey){
  var unique;
      switch (refKey) {
        case "gender":
          unique = uniqueArray([...cls.genderOptions, ...options]);
          if (~unique.findIndex((e) => e.toLowerCase() === "all")) {
            cls.filterForm.genders = ["All"]
          }
          cls.genderOptions = unique;
          break;
        case "drink":
          unique = uniqueArray([...cls.drinkOptions, ...options]);
          if (~unique.findIndex((e) => e.toLowerCase() === "all")) {
            cls.filterForm.drinks = ["All"]
          }
          cls.drinkOptions = unique;
          break;
        case "brand":
          unique = uniqueArray([...cls.brandOptions, ...options]);
          if (~unique.findIndex((e) => e.toLowerCase() === "all")) {
            cls.filterForm.brands = ["All"]
          }
          cls.brandOptions = unique;
          break;
        default:
          break;
      }
}

export function all(list, value){
  return list.every(item => item === value)
}

export function any(list, value){
  return list.some(item => item === value)
}

export function all_true(list){
  return all(list, true)
}

export function any_true(list){
  return any(list, true)
}

export function all_false(list){
  return all(list, false)
}

export function any_false(list){
  return any(list, false)
}

export function arrIsin(arr, compare){
  return arr.some(item => compare.some(e => e.toString().toLowerCase() === item.toString().toLowerCase()))
}

export function isin(arr, compare){
  return arr.some(item => item.toString().toLowerCase() === compare.toString().toLowerCase())
}

export function dateToLocalize(date) {
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}

export function dateStringToLocalize(dateStr) {
  return dateToLocalize(new Date(dateStr));
}