<template>
  <b-card>
    <FullCalendar :options="calendarOptions" ref="calendar" id="event-calendar" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  props: ["events", "selectedEvent", "eventClick", "dateClick"],
  components: {
    FullCalendar,
    BCard,
  },
  setup(props) {
    return {
      internalEventClick: props.eventClick || ((event) => {}),
      internalDateClick: props.dateClick || ((date) => {}),
    };
  },
  data() {
    return {
      calendarOptions: {
        timeZone: "UTC",
        aspectRatio: 2.5,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          left: "prev,next",
          center: "title",
          // right: "dayGridMonth,timeGridWeek,timeGridDay",
          right: "dayGridMonth,timeGridWeek",
        },
        eventTimeFormat: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        },
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        events: this.events,
      },
    };
  },
  watch: {
    events: {
      handler: function (val) {
        this.$emit("update:events", val);
        this.calendarOptions.events = val;
      },
      deep: true,
    },
    selectedEvent: {
      handler: function (val) {
        this.$emit("update:selectedEvent", val);
        this.fakeClickCalendarReRender()
      },
      deep: true,
    },
  },
  mounted(){
    this.fakeClickCalendarReRender()
    const w = this
    setTimeout(() => {
      w.fakeClickCalendarReRender()
    }, 1000);
  },
  methods: {
    handleDateClick(arg) {
      this.internalDateClick(arg.date);
    },
    handleEventClick(arg) {
      const { event } = arg;
      this.internalEventClick(event);
    },
    fakeClickCalendarReRender(){
      this.$nextTick(() => {
        const monthEl = this.$refs.calendar.$el.querySelector('button[title="month view"]')
        // const weekEl = this.$refs.calendar.$el.querySelector('button[title="week view"]')
        monthEl.click()
      });
    }
  },
};
</script>

<style>
#event-calendar .fc-toolbar-title {
  text-align: center;
}
</style>