<template>
  <b-card title="Upcoming Events">
    <div v-if="eventsFiltered.length">
      <b-row>
        <b-col>
          <b-table
            name="upcoming-events-table"
            id="upcoming-table"
            striped
            hover
            responsive
            :items="eventsFiltered"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template #cell(image)="data">
              <img :src="data.value" />
            </template>
          </b-table>
          <hr />
          <b-pagination
            name="upcoming-events-pagination"
            v-model="currentPage"
            :total-rows="eventsFiltered.length"
            :per-page="perPage"
            aria-controls="upcoming-table"
            align="fill"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div>No events upcoming</div>
    </div>
  </b-card>
</template>

<script>
import { BTable, BPagination, BCard, BRow, BCol } from "bootstrap-vue";
export default {
  name: "event-upcoming",
  props: ["events"],
  components: {
    BTable,
    BPagination,
    BCard,
    BRow,
    BCol
  },
  watch: {
    events: {
      handler: function (val) {
        this.$emit("update:events", val);
      },
      deep: true,
    },
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      fields: [
        { key: "image", label: "" },
        { key: "title", label: "" },
        "target",
        "reserved",
        "waiting",
      ],
    };
  },
  computed: {
    eventsFiltered() {
      const today = new Date().setHours(0, 0, 0, 0); // get today at midnight
      return this.events
        .filter((e) => new Date(e.date || e.start) >= today)
        .map(this.transformEvents);
    },
  },
  methods: {
    transformEvents(event) {
      return {
        image: event.image || "",
        id: event.id || "",
        title: event.title || "",
        target: event.target || "-",
        reserved: event.reserved || "-",
        waiting: event.waiting || "-",
      };
    },
  },
};
</script>
